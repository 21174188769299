import { Button } from '@components/buttons';
import { Draggable, DraggableProvider, DragState } from '@components/draggable';
import { Dropdown, MenuItem } from '@components/dropdown';
import {
  IcoCheckCircle,
  IcoDocument,
  IcoDotsHorizontal,
  IcoGripper,
  IcoTrash,
} from '@components/icons';
import { useCurrentTenant } from '@components/router/session-context';
import { Toggle } from '@components/toggle';
import { filepicker } from 'client/lib/filepicker';
import { Minidoc } from 'client/lib/minidoc';
import { delayedDebounce } from 'client/utils/debounce';
import { on } from 'minidoc-editor';
import { StateUpdater, useMemo, useEffect, useRef, Dispatch } from 'preact/hooks';
import { DownloadableFile, Downloads } from 'server/types';
import { virtualFileUrl } from 'shared/media';
import { indexBy, uniqueBy } from 'shared/utils';
import { substateUpdater } from '../../lib/hooks';
import { LessonPane } from './lesson-pane';
import { MediaState } from '@components/media-card/media-uploader';
import { showDialog } from '@components/dialog';

export const emptyState: Downloads = { title: '', files: [] };

type EmbeddedFilesChangeHandler = (added: DownloadableFile[], removed: Set<string>) => void;

/**
 * A bit of a hack. This scans the document for minidoc media cards and
 * exposes the underlying file information for use in the downloads section.
 */
function listEmbeddedFiles(el: Element) {
  const cards = Array.from(el.querySelectorAll('mini-card[type=media]'));
  return cards
    .map((el) => {
      const state = el.getAttribute('state');
      try {
        if (state) {
          const data = JSON.parse(state);
          // Ignores type and ratio fields
          return {
            name: data.name as string,
            url: data.url as string,
          };
        }
      } catch (err) {
        console.error(err);
        return;
      }
    })
    .filter((x) => {
      if (!x) {
        return false;
      }

      return (
        x.name &&
        typeof x.url === 'string' &&
        x.url.startsWith('/files/') &&
        !x.url.startsWith('data:')
      );
    }) as DownloadableFile[];
}

/**
 * Call onEmbeddedFilesChanged whenever the editor's embedded files change.
 * Returns a map of the embedded files.
 */
export function useEmbeddedFileWatcher(
  files: DownloadableFile[],
  onEmbeddedFilesChanged: EmbeddedFilesChangeHandler,
  editor: Minidoc,
) {
  const ref = useMemo(() => {
    // We use a memo, instead of a ref, so that it can be initialized efficiently.
    return {
      current: new Set(listEmbeddedFiles(editor.root).map((f) => f.url)),
    };
  }, []);

  // We use this to track all files in the downloads section. Downloads can be
  // added / remvoed from the lesson content. If a downloadable file is added
  // to the lesson, we want to keep all of its previous settings.
  const filesRef = useRef(files);
  filesRef.current = files;

  // Watch the editor for changes, and compute the change to embedded files, if any.
  useEffect(() => {
    return on(
      editor.root,
      'mini:change',
      delayedDebounce(() => {
        let files = listEmbeddedFiles(editor.root);
        const prev = ref.current;
        if (files.length === prev.size && files.every((f) => prev.has(f.url))) {
          return;
        }
        const downloads = indexBy((x) => x.url, filesRef.current);
        files = files.map((f) => downloads[f.url] || f);
        ref.current = new Set(files.map((f) => f.url));
        const added = files.filter((f) => !prev.has(f.url));
        const removed = new Set(Array.from(prev.keys()).filter((k) => !ref.current.has(k)));
        onEmbeddedFilesChanged(added, removed);
      }, 250),
    );
  }, [onEmbeddedFilesChanged, editor]);

  return ref.current;
}

export function DownloadsEditor({
  downloads,
  isVisible,
  onCancel,
  setDownloads,
  editor,
}: {
  onCancel?(): void;
  isVisible: boolean;
  downloads: Downloads;
  setDownloads: Dispatch<StateUpdater<Downloads>>;
  editor: Minidoc;
}) {
  const { terminology } = useCurrentTenant();

  const setFiles = useMemo(
    () =>
      substateUpdater(
        setDownloads,
        (s) => s.files,
        (s, files) => ({ ...s, files }),
      ),
    [setDownloads],
  );

  const onEmbeddedFilesChanged: EmbeddedFilesChangeHandler = useMemo<EmbeddedFilesChangeHandler>(
    () => (added, removed) => {
      setFiles((files) =>
        uniqueBy((x) => x.url, files.filter((f) => !removed.has(f.url)).concat(added)),
      );
    },
    [setFiles],
  );

  const embeddedFiles = useEmbeddedFileWatcher(downloads.files, onEmbeddedFilesChanged, editor);

  const updateFile = (url: string, f: (dl: DownloadableFile) => DownloadableFile) =>
    setFiles((s) => s.map((x) => (x.url === url ? f(x) : x)));

  const setName = (url: string, name: string) => updateFile(url, (f) => ({ ...f, name }));

  const toggleEnabled = (url: string) =>
    updateFile(url, (f) => ({ ...f, isEnabled: !f.isEnabled }));

  const removeDownload = (url: string) => setFiles((s) => s.filter((x) => x.url !== url));

  const addToLesson = (dl: DownloadableFile) => editor.insertMedia(dl);

  const deleteRequested = async (url: string, name: string) => {
    if (
      await showDialog({
        mode: 'warn',
        title: 'Remove download?',
        children: `Remove ${name} from the downloads section?`,
        confirmButtonText: 'Remove download',
      })
    ) {
      removeDownload(url);
    }
  };

  const reorder = (dragState: DragState) => {
    setFiles((s) => {
      const result = [...s];
      const fromIndex = result.findIndex((x) => x.url === dragState.dragging.id);
      if (fromIndex < 0) {
        return result;
      }
      const items = result.splice(fromIndex, 1);
      const toIndex =
        result.findIndex((x) => x.url === dragState.target.id) +
        (dragState.direction === 'after' ? 1 : 0);
      result.splice(toIndex, 0, ...items);
      return result;
    });
  };

  const addFile = (file: DownloadableFile) => setFiles((s) => [...s, file]);

  useEffect(() => {
    // This hackery is due to us not setting downloads properly when migrating
    // content from v1. It also might prove to be a handy fallback for when
    // we've managed to create lessons without the correct downloads array.
    // For cases where this finds new downloadable content, this will cause
    // a re-render of the editor, but that should only happen once per lesson.
    const media = Array.from(document.querySelectorAll('mini-card[type=media]')).reduce(
      (acc, el) => {
        const state = el.getAttribute('state');
        const mediaState = state && JSON.parse(state);
        // Ignore data-urls, which are mosly coming from v1 migrations.
        if (!!mediaState?.url && !mediaState.url.startsWith('data:')) {
          acc.push(mediaState);
        }
        return acc;
      },
      [] as MediaState[],
    );

    const existing = new Set(downloads.files.map((f) => f.url));
    const missing = media.filter((m) => !existing.has(m.url));
    if (missing.length) {
      setDownloads((x) => ({
        ...x,
        files: [
          ...x.files,
          ...missing.map<DownloadableFile>((m) => ({
            url: m.url,
            name: m.name,
            type: m.type,
            isEnabled: false,
          })),
        ],
      }));
    }
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <LessonPane isVisible={isVisible} hide={onCancel} title="Downloads">
      <div class="flex flex-col">
        {downloads.files.length > 0 && (
          <DraggableProvider
            canHandleDrop={(_, table) => table === 'lesson-downloads'}
            onDragComplete={() => {}}
            onTargetChange={reorder}
          >
            {downloads.files.map((dl) => (
              <Draggable
                key={dl.url}
                table="lesson-downloads"
                id={dl.url}
                class="md:p-2 mb-2 flex items-center relative rounded-md border"
              >
                <span class="text-gray-400 cursor-move mr-1" draggable>
                  <IcoGripper class="w-4 h-4 -mb-0.5" />
                </span>
                <input
                  type="text"
                  placeholder="File name"
                  class="md:p-1 border-transparent bg-transparent grow rounded hover:border-indigo-200 md:mr-2"
                  value={dl.name}
                  onInput={(e: any) => setName(dl.url, e.target.value)}
                />
                <Dropdown
                  hideDownIcon
                  triggerClass="focus:ring"
                  renderMenu={() => (
                    <div class="p-2 pb-0 flex flex-col">
                      <MenuItem
                        class="flex p-2 hover:bg-gray-50 items-center rounded-md"
                        onClick={() => toggleEnabled(dl.url)}
                      >
                        <IcoCheckCircle
                          class={`w-4 h-4 ${dl.isEnabled ? 'opacity-25' : 'text-green-400'}`}
                        />
                        <span class="ml-2">
                          {dl.isEnabled ? 'Disable download' : 'Enable download'}
                        </span>
                      </MenuItem>
                      {dl.type && !embeddedFiles.has(dl.url) && (
                        <MenuItem
                          class="flex p-2 hover:bg-gray-50 items-center rounded-md"
                          onClick={() => addToLesson(dl)}
                        >
                          <IcoDocument />
                          <span class="ml-2">Add to {terminology.lesson} content</span>
                        </MenuItem>
                      )}
                      {!embeddedFiles.has(dl.url) && (
                        <MenuItem
                          class="flex p-2 hover:bg-red-500 hover:text-white items-center rounded-md"
                          onClick={() => deleteRequested(dl.url, dl.name)}
                        >
                          <IcoTrash />
                          <span class="ml-2">Delete</span>
                        </MenuItem>
                      )}
                    </div>
                  )}
                >
                  <span class="inline-flex md:hidden items-center hover:bg-gray-100 rounded-md p-1">
                    <IcoCheckCircle
                      class={`w-4 h-4 ${dl.isEnabled ? 'text-green-400' : 'text-gray-200'} mr-2`}
                    />
                    <IcoDotsHorizontal class="rotate-90 w-4 h-4 opacity-75" />
                  </span>
                </Dropdown>
                <div class="hidden md:flex items-center ml-2">
                  <Button
                    class={`${
                      embeddedFiles.has(dl.url) ? 'invisible' : ''
                    } p-2 mr-2 text-gray-500 hover:bg-red-500 hover:text-white focus:ring-2 focus:ring-red-500 outline-none rounded`}
                    onClick={() => deleteRequested(dl.url, dl.name)}
                  >
                    <IcoTrash class="w-4 h-4" />
                  </Button>
                  <Toggle checked={dl.isEnabled} onClick={() => toggleEnabled(dl.url)} />
                </div>
              </Draggable>
            ))}
          </DraggableProvider>
        )}
        <footer class={`flex flex-wrap items-center ${downloads.files.length ? 'mt-2' : 'p-2'}`}>
          {!downloads.files.length && <p class="text-gray-600">There are no attached files.</p>}

          <Button
            class="px-2 text-indigo-600 hover:text-pink-600 font-medium"
            onClick={() => {
              filepicker().then((f) => {
                f &&
                  addFile({
                    name: f.name,
                    type: f.type,
                    url: virtualFileUrl(f.fileId, f.filePath),
                    isEnabled: true,
                  });
              });
            }}
          >
            + Add Download
          </Button>
        </footer>
      </div>
    </LessonPane>
  );
}
