import register from 'preact-custom-element';
import { VidstackPlayer, Props as VidstackProps } from '@components/vidstack-player';

export type Props = VidstackProps;
export const tagName = 'vidstack-card';

function Wrapper(
  props: Props & {
    hidecaptions?: string;
  },
) {
  return (
    <VidstackPlayer
      {...props}
      // Re-assign the HTML attribute to the correct prop name
      hideCaptions={props.hidecaptions === 'true'}
    />
  );
}

register(
  Wrapper,
  tagName,
  ['url', 'poster', 'captions', 'downloadUrl', 'type', 'load', 'overlay', 'ratio', 'hidecaptions'],
  {
    shadow: false,
  },
);
