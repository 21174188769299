import { router } from '@components/router';
import { UpsellsPage } from './upsells-page';
import { ModalPage } from './modal-page';
import { AsyncForm, FormGroup } from '@components/async-form';
import { autoFocusSelf } from 'client/utils/autofocus';
import { BtnPrimary } from '@components/buttons';
import { IcoArrowRight } from '@components/icons';
import { rpx } from 'client/lib/rpx-client';
import { showLostChangesWarning } from './lost-changes-warning';

function Page() {
  return (
    <UpsellsPage title="New upsell">
      <ModalPage
        onClose={() => showLostChangesWarning({ gotoHref: `/upsells`, hasUnsavedChanges: true })}
      >
        <section class="border-t md:border md:rounded-2xl flex flex-col gap-6 p-10">
          <h2 class="text-2xl">Create a new upsell</h2>
          <div class="flex flex-col gap-6">
            <AsyncForm
              class="flex flex-col gap-6"
              onSubmit={async (data) => {
                const upsell = await rpx.upsells.createUpsell({ title: data.title });
                router.goto(`/upsells/${upsell.id}/new`);
              }}
            >
              <FormGroup prop="title" class="w-full">
                <label class="flex flex-col gap-1">
                  <span>Title</span>
                  <input
                    required
                    ref={autoFocusSelf}
                    placeholder="Upsell title"
                    name="title"
                    class="ruz-input"
                  />
                </label>
              </FormGroup>
              <footer>
                <BtnPrimary class="gap-2 rounded-full">
                  <span>Create upsell</span> <IcoArrowRight />
                </BtnPrimary>
              </footer>
            </AsyncForm>
          </div>
        </section>
      </ModalPage>
    </UpsellsPage>
  );
}

router.add({ url: 'upsells/new', render: Page, authLevel: 'guide' });
