import { Subtext, useAutoFormState } from '@components/async-form';
import { Button } from '@components/buttons';
import { IcoDuplicate, IcoTrash } from '@components/icons';
import { useState } from 'preact/hooks';
import { AccessFormat } from 'server/types';
import { DateInput, TimezoneDiff } from '@components/date-picker';
import { TimeInput } from '@components/time-input';
import { showToast } from '@components/toaster';
import { Module } from './types';
import { offsetToTime, timeToOffset } from './fns';
import { showError } from '@components/app-error';
import { DefaultSpinner, Spinner } from '@components/spinner';
import { useCurrentTenant } from '@components/router/session-context';
import { rpx, RpxResponse } from 'client/lib/rpx-client';
import { timezoneCity } from 'shared/dateutil';
import { Toggle } from '@components/toggle';
import { useTimezone, useTryAsyncData } from 'client/lib/hooks';
import { Checkbox } from '@components/checkbox';
import { PriceSummary } from 'client/pages/pmts/components/price-summary';
import { showDialog } from '@components/dialog';
import { ModalForm } from '@components/modal-form';

const store = rpx.modules;

export interface ModuleFormProps {
  courseId: UUID;
  accessFormat: AccessFormat;
  isAbsoluteSchedule: boolean;
  id: UUID;
  title: string;
  startDate?: Date;
  startOffset?: number;
  prices?: string[];
  isDraft?: boolean;
  onDelete(): void;
  onCopy?(): void;
  onUpdate(
    opts: Pick<Module, 'title' | 'startDate' | 'prices' | 'startOffset' | 'isDraft'>,
  ): Promise<unknown>;
  onClose(): void;
}

export function ModuleForm(props: ModuleFormProps) {
  const { terminology } = useCurrentTenant();
  const [isDeleting, setIsDeleting] = useState(false);

  const { accessFormat } = props;
  const timezone = useTimezone();
  const [data, ctx] = useAutoFormState({
    initialState: {
      title: props.title,
      startDate: props.startDate,
      offsetTime: offsetToTime(props.startOffset),
      restrictToPrices: !!props.prices?.length,
      prices: props.prices,
      isDraft: props.isDraft,
    },
  });
  const prices = useTryAsyncData<RpxResponse<typeof rpx.prices.getCoursePrices>>(async () => {
    if (!data.restrictToPrices) {
      return [] as unknown as RpxResponse<typeof rpx.prices.getCoursePrices>;
    }
    return rpx.prices.getCoursePrices({ courseId: props.courseId });
  }, [data.restrictToPrices]);

  const promptForDelete = async () => {
    try {
      const confirmed = await showDialog({
        mode: 'warn',
        title: `Permanently delete ${terminology.module} and related ${terminology.lessons}?`,
        confirmButtonText: `Permanently delete ${terminology.module}`,
        children: (
          <>
            Are you sure you want to delete {terminology.module} &quot;
            <em class="text-black">{props.title}</em>&quot;? All of the {terminology.lessons} and
            attachments will be permanently removed from our servers. This action cannot be undone.
          </>
        ),
      });
      if (!confirmed) {
        return;
      }
      setIsDeleting(true);
      await store.deleteModule({ id: props.id });
      props.onDelete();
      showToast({
        type: 'ok',
        title: `${terminology.Module} #${props.id} deleted`,
        message: `Deleted "${props.title}".`,
      });
    } catch (err) {
      showError(err);
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <ModalForm
      title={`Edit ${terminology.module}`}
      confirmButtonText="Save"
      onClose={props.onClose}
      onSubmit={async (frm) => {
        if (prices.isLoading) {
          return;
        }
        return props.onUpdate({
          title: frm.title,
          startDate: data.startDate ? new Date(data.startDate) : undefined,
          startOffset: frm.offsetTime ? timeToOffset(frm.offsetTime) : undefined,
          prices: frm.prices,
          isDraft: !!frm.isDraft,
        });
      }}
    >
      <section class="flex flex-col gap-6">
        <div>
          <label class="block mb-2 caret-course-nav-reverse capitalize">
            {terminology.module} title
          </label>
          <section class="w-full mb-2">
            <input
              type="text"
              placeholder={`${terminology.module} title`}
              name="title"
              value={data.title}
              class="ruz-input block sm:text-sm sm:leading-5 bg-transparent w-full"
            />
          </section>
        </div>
        <div>
          {accessFormat === 'ondemand' && <TimeInput time={data.offsetTime} name="offsetTime" />}
          {accessFormat === 'scheduled' && (
            <>
              <label class="block mb-2">Available on</label>
              <section class="mb-2">
                <span class="inline-flex">
                  <DateInput
                    name="startDate"
                    includeTime
                    class={props.isAbsoluteSchedule ? 'rounded-r-none' : ''}
                    value={data.startDate}
                    onChange={(startDate) => ctx.setState((s) => ({ ...s, startDate }))}
                  />
                  {props.isAbsoluteSchedule && (
                    <span class="flex p-2 px-4 items-center border border-gray-300 border-l-0 rounded-r bg-gray-50">
                      {timezoneCity(timezone)} time
                    </span>
                  )}
                </span>
              </section>
            </>
          )}
          {(accessFormat === 'scheduled' || accessFormat === 'ondemand') && (
            <>
              {props.isAbsoluteSchedule && <TimezoneDiff date={data.startDate} />}
              {!props.isAbsoluteSchedule && (
                <div>
                  <Subtext>(in student's timezone)</Subtext>
                </div>
              )}
            </>
          )}
        </div>
        <div>
          <label class="cursor-pointer">
            <Toggle name="isDraft" checked={data.isDraft} />
            <span class="ml-3">Draft</span>
          </label>
        </div>

        <div>
          <label class="cursor-pointer flex">
            <Toggle name="restrictToPrices" checked={data.restrictToPrices} />
            <span class="ml-3">
              Restrict access to certain price points
              {data.restrictToPrices && (
                <span class="text-gray-500 block">Note: invitees will not be restricted.</span>
              )}
            </span>
          </label>
        </div>
        {data.restrictToPrices && !prices.data && <DefaultSpinner />}
        {data.restrictToPrices && !!prices.data && (
          <div class="border rounded-md my-4 py-2 flex flex-col">
            {prices.data.map((p) => (
              <Checkbox
                key={p.id}
                checked={data.prices?.includes(p.id)}
                name="[]prices"
                value={p.id}
                wrapperClass="hover:bg-gray-50 p-4 py-2 cursor-pointer border-b"
                class="ml-4"
              >
                <strong class="block">{p.name}</strong>
                <PriceSummary price={p} />
              </Checkbox>
            ))}
          </div>
        )}
      </section>

      <div class="border rounded-2xl p-4 mt-8 flex flex-col gap-4" onClick={props.onClose}>
        <h3 class="font-semibold p-2 pb-0">More actions...</h3>
        <Button
          class="flex w-full items-center rounded-lg p-2 hover:bg-red-50 hover:text-red-600"
          type="button"
          disabled={isDeleting}
          onClick={promptForDelete}
        >
          {!isDeleting && <IcoTrash class="w-4 h-4 opacity-75 mr-3" />}
          {isDeleting && <Spinner class="inline-block border-indigo-400 w-4 h-4 mr-3" />}
          <span>Delete {terminology.module}</span>
        </Button>
        {props.onCopy && (
          <Button
            class="flex w-full rounded-lg p-2 hover:bg-gray-100"
            type="button"
            onClick={props.onCopy}
          >
            <IcoDuplicate class="w-4 h-4 opacity-75 mr-3 mt-1" />
            <span class="flex flex-col text-left">
              <span>Copy {terminology.module}</span>
              <span class="opacity-75 text-sm">
                Copy this {terminology.module} and all of its lessons to this or another course.
              </span>
            </span>
          </Button>
        )}
      </div>
    </ModalForm>
  );
}
