import { router } from '@components/router';
import { UpsellsPage } from './upsells-page';
import { AppRoute } from 'client/lib/app-route/types';
import { LoadedProps } from 'client/lib/loaders';
import { Button } from '@components/buttons';
import {
  IcoArchive,
  IcoArrowLeft,
  IcoCheckCircle,
  IcoChevronDown,
  IcoDotsHorizontal,
  IcoEye,
  IcoInfo,
  IcoPencil,
  IcoXCircle,
} from '@components/icons';
import { ModalForm, showModalForm } from '@components/modal-form';
import { showError } from '@components/app-error';
import { Dropdown } from '@components/dropdown';
import { showUpsellPreviewModal } from './checkout-modal';
import { rpx } from 'client/lib/rpx-client';
import { ApplyToTab } from './apply-to-tab';
import { FullUpsell } from './types';
import { OffersTab } from './offers-tab';
import { SignupsTab } from './signups-tab';
import { showDialog } from '@components/dialog';

type Tab = 'offers' | 'applyto' | 'signups';

type State = {
  upsell: FullUpsell;
};

async function load(props: AppRoute): Promise<State> {
  const upsellId = props.params.id;
  const upsell = await rpx.upsells.getUpsell({ upsellId });
  if (!upsell) {
    throw new Error(`Upsell not found!`);
  }
  rpx.upsells.updateUpsellOpenedAt({ upsellId }).catch(console.error);
  return { upsell };
}

type Props = LoadedProps<typeof load>;

function Tab(props: { href: string; text: string; count: number; isSelected: boolean }) {
  return (
    <Button
      href={props.href}
      class={`flex items-center gap-2 rounded-md font-semibold p-1 px-4 ${
        props.isSelected ? 'bg-indigo-100 text-indigo-800' : 'hover:bg-gray-100 text-inherit'
      }`}
    >
      <span>{props.text}</span>
      <span
        class={`hidden rounded-full px-2.5 text-xs font-medium sm:inline-flex items-center ml-auto ${
          props.isSelected ? 'bg-indigo-200' : 'bg-gray-100'
        }`}
      >
        {props.count}
      </span>
    </Button>
  );
}

function Page(props: Props) {
  const { setState } = props;
  const { upsell } = props.state;
  const tab: Tab = (props.route.params.tab as Tab) || 'offers';

  const showRenameUpsellModal = () =>
    showModalForm(() => (
      <ModalForm
        title="Rename upsell"
        onSubmit={async (data) => {
          const updated = { ...upsell, title: data.title };
          await rpx.upsells.saveUpsell({
            upsellId: updated.id,
            title: updated.title,
          });
          setState((s) => ({ ...s, upsell: updated }));
        }}
      >
        <input name="title" value={upsell.title} placeholder="Upsell title" class="ruz-input" />
      </ModalForm>
    ));

  const saveUpsellState = async (opts: { isEnabled: boolean; isArchived: boolean }) => {
    try {
      await rpx.upsells.setIsArchived({
        upsellId: upsell.id,
        isArchived: opts.isArchived,
        isEnabled: opts.isEnabled,
      });
      setState((s) => ({
        ...s,
        upsell: { ...s.upsell, isArchived: opts.isArchived, isEnabled: opts.isEnabled },
      }));
    } catch (err) {
      showError(err);
    }
  };

  const showToggleArchiveUpsellModal = async () => {
    if (!upsell.isArchived) {
      const ok = await showDialog({
        title: 'Archive upsell?',
        children: `This will disable the upsell and change how it displays in your management screens.`,
        confirmButtonText: 'Archive upsell',
        mode: 'warn',
      });
      if (!ok) {
        return;
      }
    }
    await saveUpsellState({ isArchived: !upsell.isArchived, isEnabled: false });
  };

  return (
    <UpsellsPage title={upsell.title}>
      <section class="flex flex-col gap-4">
        <header class="flex flex-col py-4 border-b">
          <nav>
            <Button href="/upsells" class="gap-2 inline-flex items-center text-gray-500 text-sm">
              <IcoArrowLeft /> Back to upsells
            </Button>
          </nav>
          <div class="flex items-center gap-4">
            <h1 class="text-2xl">
              <button onClick={showRenameUpsellModal}>{upsell.title}</button>
            </h1>
            <Dropdown
              triggerClass="hover:bg-gray-100 size-8 rounded-full inline-flex items-center justify-center transition-all"
              hideDownIcon
              noPadding
              renderMenu={() => (
                <section class="p-2 flex flex-col">
                  <Button
                    class="flex items-center gap-2 p-2 rounded hover:bg-gray-100"
                    onClick={showRenameUpsellModal}
                  >
                    <IcoPencil />
                    <span>Rename upsell</span>
                  </Button>
                </section>
              )}
            >
              <IcoDotsHorizontal />
            </Dropdown>
            <nav class="ml-auto flex items-center gap-4 pb-1.5">
              {!!upsell.numOffers && (
                <button
                  class="flex items-center gap-2 border rounded-full pl-3 pr-4 py-1 hover:bg-gray-50"
                  onClick={() => showUpsellPreviewModal(upsell as any /*TODO*/)}
                >
                  <IcoEye />
                  Preview
                </button>
              )}
              <Dropdown
                hideDownIcon
                noPadding
                renderMenu={() => (
                  <section class="p-2 flex flex-col">
                    <Button
                      class="flex items-center gap-2 p-2 rounded hover:bg-red-50 hover:text-red-600"
                      onClick={showToggleArchiveUpsellModal}
                    >
                      <IcoArchive />
                      <span>{upsell.isArchived ? 'Unarchive' : 'Archive'} upsell</span>
                    </Button>
                    {upsell.isEnabled && (
                      <Button
                        class="flex items-center gap-2 p-2 rounded hover:bg-gray-50"
                        onClick={() =>
                          saveUpsellState({ isEnabled: false, isArchived: !!upsell.isArchived })
                        }
                      >
                        <IcoXCircle />
                        <span>Close signups</span>
                      </Button>
                    )}
                    {!upsell.isEnabled && (
                      <Button
                        class="flex items-center gap-2 p-2 rounded hover:bg-gray-50"
                        onClick={() => saveUpsellState({ isEnabled: true, isArchived: false })}
                      >
                        <IcoCheckCircle />
                        <span>Open signups</span>
                      </Button>
                    )}
                  </section>
                )}
              >
                <span
                  class={`inline-flex gap-2 items-center rounded-full border pl-3 pr-2 p-1 ${
                    upsell.isArchived
                      ? 'bg-red-50 border-red-200 text-red-600'
                      : upsell.isEnabled
                      ? 'bg-green-50 border-green-200 text-green-600'
                      : 'bg-gray-50'
                  }`}
                >
                  <span>
                    {upsell.isArchived
                      ? 'Archived'
                      : upsell.isEnabled
                      ? 'Signups open'
                      : 'Signups closed'}
                  </span>
                  <IcoChevronDown />
                </span>
              </Dropdown>
            </nav>
          </div>
        </header>

        <div class="flex gap-10 py-6">
          <nav class="flex flex-col gap-4 min-w-60">
            <Tab
              href="?tab=offers"
              isSelected={tab === 'offers'}
              text="Offers"
              count={upsell.numOffers}
            />
            <Tab
              href="?tab=applyto"
              isSelected={tab === 'applyto'}
              text="Apply to"
              count={upsell.numAppliedPrices}
            />
            <Tab
              href="?tab=signups"
              isSelected={tab === 'signups'}
              text="Signups"
              count={upsell.numSignups}
            />
          </nav>

          <section class="flex flex-col gap-4 flex-grow">
            {!upsell.numAppliedPrices && (
              <header class="border-l-8 border-indigo-600 bg-indigo-50 p-6 px-8 mb-4">
                <span class="font-semibold text-indigo-600 flex items-center gap-1.5">
                  <IcoInfo class="size-5" />
                  Note
                </span>
                <p>
                  This upsell is not applied to any courses. It won't be offered to anyone until you{' '}
                  <a class="underline" href="/upsells/up-S7zTbt-Qxgu2-c60DLFdUw?tab=applyto">
                    apply the upsell
                  </a>{' '}
                  to a course or product.
                </p>
              </header>
            )}
            {tab === 'applyto' && <ApplyToTab upsell={upsell} />}
            {tab === 'offers' && (
              <OffersTab
                upsell={upsell}
                offers={upsell.offers.map((o) => ({ ...o, id: o.productId }))}
                onReorder={(offers) => {
                  rpx.upsells
                    .reorderOffers({
                      upsellId: upsell.id,
                      productIds: offers.map((o) => o.productId),
                    })
                    .catch(showError);
                  setState((s) => ({ ...s, upsell: { ...s.upsell, offers } }));
                }}
              />
            )}
            {tab === 'signups' && <SignupsTab upsell={upsell} />}
          </section>
        </div>
      </section>
    </UpsellsPage>
  );
}

router.add({ url: 'upsells/:id', render: Page, authLevel: 'guide', load });
router.add({ url: 'upsells/:id/offers', render: Page, authLevel: 'guide', load });
router.add({ url: 'upsells/:id/applied-to', render: Page, authLevel: 'guide', load });
