import { AccessFormat, MessageRow } from 'server/types';
import { FormGroup, Subtext } from '@components/async-form';
import { useState } from 'preact/hooks';
import { TimeInput } from '@components/time-input';
import { offsetToTime, timeToOffset } from '@components/module-helpers';
import { toLocalDate, toNullableDate } from 'shared/dateutil';
import { SendButtonContent, TimezoneDiff } from '@components/date-picker';
import { DatePicker, mkdate } from '@components/date-picker';
import { ModalForm } from '@components/modal-form';

interface Props {
  hide(): void;
  accessFormat: AccessFormat;
  isAbsoluteSchedule: boolean;
  startDate?: string;
  startOffset?: number;
  applySchedule(schedule: Pick<MessageRow, 'startOffset' | 'startDate'>): Promise<unknown>;
}

export function ScheduleModal(props: Props) {
  const scheduleDate = props.accessFormat === 'scheduled' || props.accessFormat === 'openaccess';
  const [time, setTime] = useState(() => offsetToTime(props.startOffset));
  const [startDate, setStartDate] = useState(() => {
    if (!props.startDate) {
      return mkdate((dt) => {
        dt.setHours(dt.getHours(), 0, 0, 0);
        dt.setDate(dt.getDate() + 7);
      });
    }
    return props.isAbsoluteSchedule
      ? toNullableDate(props.startDate)
      : toLocalDate(props.startDate);
  });

  return (
    <ModalForm
      contentWidth
      onClose={props.hide}
      title="When do you want to send this message?"
      onSubmit={() =>
        props.applySchedule({
          startDate,
          startOffset: timeToOffset(time),
        })
      }
      confirmButtonText={
        <>
          {!props.isAbsoluteSchedule && 'Schedule Message'}
          {props.isAbsoluteSchedule && startDate && (
            <SendButtonContent prefix="Send on " date={startDate} />
          )}
        </>
      }
    >
      <div class="flex flex-col items-start">
        <div class="inline-flex">
          {scheduleDate && <DatePicker value={startDate} onChange={setStartDate} />}
          {!scheduleDate && (
            <FormGroup prop="startDate">
              <TimeInput time={time} setTime={(t1) => setTime((t2) => ({ ...t2, ...t1 }))} />
            </FormGroup>
          )}
        </div>
      </div>

      <footer class="my-4">
        {props.isAbsoluteSchedule && <TimezoneDiff />}
        {!props.isAbsoluteSchedule && (
          <Subtext>(Times are in the student's time zone and are approximate.)</Subtext>
        )}
      </footer>
    </ModalForm>
  );
}
