import { RouteProps, router, useRouteParams } from '@components/router';
import { AppRoute } from 'client/lib/app-route/types';
import { StudentPage } from '@components/student-page';
import { useAsyncEffect } from 'client/utils/use-async-effect';
import { UserProfileIcon } from '@components/avatars';
import { Pill } from '@components/pill';
import { useCurrentUser } from '@components/router/session-context';
import { BtnPrimary, Button } from '@components/buttons';
import { groupBy } from 'shared/utils';
import { moduleAvailableOn } from '@components/module-helpers';
import { StudentMeeting } from 'server/types';
import { getMeetingStatus } from 'shared/meeting-utils';
import { UpcomingEvents, WhatsNew } from '@components/course-overview';
import { URLS } from 'shared/urls';
import { Lesson, Module, ModuleCard } from '@components/module-helpers/module-card';
import { PrimaryOverviewCard } from '../guide-course-overview/overview-card';
import dayjs from 'dayjs';
import { ProgressBar } from '@components/progress-bar';
import { BundleOverview } from './bundle-overview';
import { GuideCard } from './guide-card';
import { rpx } from 'client/lib/rpx-client';
import { useIntl } from 'shared/intl/use-intl';
import { emptyLessonTitle, emptyModuleTitle } from 'shared/terminology';
import { globalConfig } from 'client/lib/auth';
import { IcoExternalLink } from '@components/icons';

const bundlesStore = rpx.guideBundles;

type Props = RouteProps<Awaited<ReturnType<typeof load>>>;
type Course = Props['data']['course'];

function WelcomeBack({
  nextLesson,
  liveMeeting,
}: {
  nextLesson?: Lesson;
  liveMeeting?: Pick<StudentMeeting, 'id' | 'title' | 'guideJoinedAt'>;
}) {
  const { courseId, courseSlug } = useRouteParams();
  const user = useCurrentUser();
  const intl = useIntl();

  if (!user) {
    return null;
  }
  return (
    <div>
      <header class="flex items-center">
        <div>
          <UserProfileIcon user={user} size="w-20 h-20 text-2xl" />
        </div>
        <div class="ml-6">
          <h3 class="text-2xl dark:text-gray-200 font-medium mb-1">
            {intl('Welcome, {name:string}!', { name: user.displayName || user.name })}
          </h3>
          <p class="text-gray-500">{dayjs().format('dddd, MMMM D')}</p>
        </div>
      </header>
      {liveMeeting && (
        <div class="flex flex-col mt-6">
          <p class="text-sm font-medium text-gray-600 dark:text-gray-100 mb-2">
            <span class="ml-1 font-bold">{liveMeeting.title}</span> meeting is
            <span class="inline-block animate-live">
              <Pill color="green">ON AIR</Pill>
            </span>
          </p>
          <div class="flex flex-col lg:flex-row lg:items-center space-y-2 lg:space-y-0 lg:space-x-2">
            <BtnPrimary
              class="w-50"
              href={URLS.student.meeting({
                course: {
                  id: courseId,
                  title: courseSlug,
                },
                meeting: liveMeeting,
              })}
            >
              Go to "{liveMeeting.title}" meeting
            </BtnPrimary>
            {nextLesson && (
              <span>
                OR
                <Button
                  class="ml-2"
                  href={URLS.student.lesson({
                    course: {
                      id: courseId,
                      title: courseSlug,
                    },
                    lesson: nextLesson,
                  })}
                >
                  Go to the next lesson
                </Button>
              </span>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

function FacilitatorsCard({ course }: { course: Course }) {
  const intl = useIntl();
  const { facilitators } = course;

  if (!facilitators.length) {
    return null;
  }

  return (
    <div class="border-t border-b border-dashed p-8 px-4 flex flex-col gap-8">
      {facilitators.map((x) => {
        return (
          <Button
            href={
              course.hidePeople
                ? ''
                : URLS.student.memberProfile({
                    course,
                    user: x,
                  })
            }
            class="flex items-center gap-4"
            key={x.id}
          >
            <UserProfileIcon user={x} size="w-8 h-8 text-lg" />
            <h3 class="font-bold">{x.name}</h3>
            <Pill>{intl('Facilitator')}</Pill>
          </Button>
        );
      })}
    </div>
  );
}

function Page(props: Props) {
  const {
    course,
    subcourses,
    modules,
    completedLessons,
    lessons,
    nextLesson,
    upcomingMeetings,
    liveMeeting,
  } = props.data;
  const { id: courseId } = course;
  const intl = useIntl();
  const currentUser = useCurrentUser();

  useAsyncEffect(async () => {
    if (!courseId) {
      return;
    }
    try {
      await rpx.courses.setLastOpenedAt({ courseId });
    } catch (err) {
      console.warn('Could not set last_opened_at', err);
    }
  }, [courseId]);

  if (course.isBundle) {
    return (
      <BundleOverview
        course={course}
        subcourses={subcourses}
        accessLevel={props.data.accessLevel}
      />
    );
  }

  return (
    <StudentPage
      currentLink="home"
      isPinnable
      course={course}
      accessLevel={props.data.accessLevel}
      sidePaneContent={
        <section class="lg:w-[40vw] xl:w-[33vw] bg-gray-50 dark:bg-gray-800 dark:text-gray-200 bg-opacity-50 border-l border-t lg:border-t-0 border-gray-100 dark:border-gray-700 lg:flex flex-col items-center py-20">
          <div class="max-w-4xl mx-auto lg:max-w-lg space-y-10 px-4 lg:px-6 xl:px-10">
            <GuideCard guide={course.guide} />
            <FacilitatorsCard course={course} />
            <UpcomingEvents meetings={upcomingMeetings} />
            <WhatsNew course={course} title={intl("What's new")} />
          </div>
        </section>
      }
    >
      <div class="flex flex-col flex-grow max-w-4xl mx-auto min-h-full px-6 md:px-12">
        <div class="lg:flex lg:space-x-10 relative z-10">
          <div class="flex-grow space-y-12 relative z-10">
            <WelcomeBack nextLesson={nextLesson} liveMeeting={liveMeeting} />

            <header>
              <div class="mb-2 flex justify-between items-center font-display font-medium text-gray-900 dark:text-gray-200">
                {intl('Course Progress')}
                <span class="text-sm">
                  {intl('{completed:number} of {total:number}', {
                    completed: completedLessons.length,
                    total: lessons.length,
                  })}
                </span>
              </div>
              <ProgressBar
                progress={100 * (completedLessons.length / lessons.length)}
                showPercent={false}
                bgClass="bg-gray-100 dark:bg-gray-600"
                barClass="bg-green-400 dark:bg-green-600"
              />
            </header>
            <PrimaryOverviewCard
              title={intl('Modules & Lessons')}
              headerActions={
                !liveMeeting &&
                nextLesson && (
                  <Button
                    class="btn-primary inline-flex items-center rounded-md pl-4 my-2 text-white"
                    href={URLS.student.lesson({
                      course: {
                        id: courseId,
                        title: course.title,
                      },
                      lesson: nextLesson,
                    })}
                  >
                    <span class="mr-2 line-clamp-2">
                      <span class="opacity-80">{intl('Continue')}: </span>
                      <span class="font-medium">{nextLesson.title}</span>
                    </span>
                    <span> ⤑</span>
                  </Button>
                )
              }
            >
              {modules.map(
                (module, index) =>
                  !module.isDraft && <ModuleCard key={module.id} module={module} index={index} />,
              )}
            </PrimaryOverviewCard>
            {!!currentUser && course.hasEnabledCert && (
              <footer class="mt-4">
                <Button
                  href={URLS.student.certificate({ course, userId: currentUser.id })}
                  target="_blank"
                  class="flex gap-2 border border-gray-200 dark:border-gray-700 rounded-md p-2 items-center justify-center hover:bg-gray-100 text-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 active:bg-gray-500"
                >
                  <span>{intl('Certificate of Completion')}</span>
                  <IcoExternalLink />
                </Button>
              </footer>
            )}
          </div>
        </div>
      </div>
    </StudentPage>
  );
}

async function load(route: AppRoute) {
  const { courseId } = route.params;
  const [course, state, meetings] = await Promise.all([
    rpx.courses.getStudentCourse({
      id: courseId,
      // Redirects users with missing profile fields to the `/profile-fields` page
      redirectIncompleteProfiles: true,
    }),
    rpx.lessons.getFullLessonState({
      courseId,
    }),
    rpx.meetings.getStudentMeetings({
      courseId,
    }),
  ]);

  const subcourses = course.isBundle
    ? await bundlesStore.getSubcourses({
        id: course.id,
      })
    : await Promise.resolve([]);

  const { completedLessons } = state;
  const tenant = globalConfig().tenant;

  const moduleLessons = groupBy((x) => x.moduleId, state.lessons);
  const modulesMap = state.modules.reduce((acc, module) => {
    const lessons = (moduleLessons[module.id] || []).map((lesson) => ({
      id: lesson.id,
      title: lesson.title || emptyLessonTitle(tenant),
      isCompleted: completedLessons.includes(lesson.id),
      isPrerequisite: lesson.isPrerequisite,
      assessmentType: lesson.assessmentType,
      hasDiscussion: !!lesson.discussion?.isEnabled,
      hasAssignment: lesson.assessmentType === 'assignment',
      href: URLS.student.lesson({ course, lesson }),
    }));
    const stats = lessons.reduce(
      (acc, lesson) => {
        if (lesson.isCompleted) {
          acc.completed++;
        }
        if (lesson.hasDiscussion) {
          acc.discussions++;
        }
        if (lesson.hasAssignment) {
          acc.assignments++;
        }

        if (!acc.nextLesson && !lesson.isCompleted) {
          acc.nextLesson = lesson;
        }

        return acc;
      },
      {
        completed: 0,
        discussions: 0,
        assignments: 0,
        nextLesson: undefined as Lesson | undefined,
      },
    );

    acc[module.id] = {
      id: module.id,
      title: module.title || emptyModuleTitle(tenant),
      isCompleted: stats.completed === lessons.length,
      completionRate: lessons.length > 0 ? Math.round((stats.completed / lessons.length) * 100) : 0,
      availableOn: moduleAvailableOn({
        module,
        accessFormat: course.accessFormat,
        membershipDate: state.membershipDate,
      }),
      lessons,
      stats,
      isDraft: module.isDraft,
    };
    return acc;
  }, {} as Record<UUID, Module>);

  const modules = Object.values(modulesMap);

  return {
    ...state,
    // Eliminate any lessons whose module is not available to this student.
    // We could (and arguably should) do this server-side, but this safeguards
    // and keeps the client consistent, regardless.
    lessons: state.lessons.filter((l) => !!modulesMap[l.moduleId]),
    course,
    subcourses,
    modules,
    // Find first uncompleted lesson
    nextLesson: modules.find((l) => !l.isCompleted)?.lessons.find((a) => !a.isCompleted),
    liveMeeting: meetings.find((m) => {
      return getMeetingStatus(m) === 'inProgress';
    }),
    upcomingMeetings: meetings.filter((m) => {
      const status = getMeetingStatus(m);
      return status !== 'ended';
    }),
  };
}

router.add({
  url: 'courses/:courseId',
  load,
  render: Page,
  authLevel: 'student',
});
