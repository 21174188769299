import { showError } from '@components/app-error';
import { ManualDom } from '@components/manual-dom';
import { ModalForm, showModalForm } from '@components/modal-form';
import { Spinner } from '@components/spinner';
import { FilePickerResult, uploadFile } from 'client/lib/filepicker';
import { useMemo, useState } from 'preact/hooks';
import { renderMediaPlayer } from './media-player';
import { StandardDialog } from '@components/dialog';

interface Props {
  url: string;
  type: string;
  isPublic: boolean;
}

async function getVideoImage(video: HTMLVideoElement) {
  const canvas = document.createElement('canvas');
  canvas.height = video.videoHeight;
  canvas.width = video.videoWidth;
  const ctx = canvas.getContext('2d')!;
  ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (b) => (b ? resolve(b) : reject(new Error(`Failed to capture image`))),
      'image/jpeg',
    );
  });
}

export function showVideoPosterModal({ type, url, isPublic }: Props) {
  return showModalForm<FilePickerResult>(({ resolve }) => {
    const [video, onVideoElement] = useState<HTMLVideoElement | undefined>(undefined);
    const player = useMemo(
      () =>
        renderMediaPlayer({
          isResolved: url.startsWith('blob:'),
          type,
          url,
          // We always use a 100:X ratio, so 56 is roughly 16:9 which is good
          // for the poster picker regardless of the video's actual ratio. This
          // allows us to fit the video into the modal.
          ratio: 56,
          // Start loading the video immediately so we can capture a frame.
          load: 'visible',
          onVideoElement,
        }),
      [],
    );

    const [saving, setSaving] = useState(false);

    const captureImage = async () => {
      if (!video) {
        return;
      }
      setSaving(true);
      try {
        const img = await getVideoImage(video);
        const result = await uploadFile({
          file: img,
          name: 'Poster',
          isPublic,
        }).promise;
        if (result) {
          resolve(result);
        } else {
          throw new Error('Upload canceled');
        }
      } catch (err) {
        setSaving(false);
        showError(err);
      }
    };

    const hide = () => resolve();

    if (saving) {
      return (
        <StandardDialog
          onClose={hide}
          title="Saving poster image..."
          subtitle={
            <div class="aspect-video">
              <Spinner class="border-indigo-600" />
            </div>
          }
        />
      );
    }

    return (
      <ModalForm
        onClose={hide}
        title="Choose a poster image"
        subtitle="Seek the video to the frame you want students to see before the video is played."
        onSubmit={captureImage}
        confirmButtonText="Set Poster Image"
      >
        <section class="rounded-lg overflow-hidden relative">
          <ManualDom el={player} class="aspect-video" />
        </section>
      </ModalForm>
    );
  });
}
